/* =================================================
   SIDEBAR
   ================================================== */

.author__avatar {
  img {
    display: block;
    max-width: 200px;
    border-radius: 15%;
  }
}

.sidebar div  {
   overflow-x: hidden;
   overflow-y: auto;
}

// Hide the elevator image from the sidebar to avoid a horizontal scrollbar
.sidebar img.autohide {
    visibility: hidden;
    display:none;
  @include breakpoint($large) {
    visibility: initial;
    display:initial;
  }
}

// derived from align-right. Switches to full width sooner to support slightly larger images (500 px)
.align-right-medium {
  display: block;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint($medium) {
    float: right;
    margin-left: 1em;
  }
}

figcaption {
  font-family: $sans-serif;
  font-weight: bold;
  font-style: italic;
}

/* =================================================
   Archive list
   ================================================== */

.list__item {
  clear: both;
  overflow: auto;
}

/* =================================================
   Taxonomy List: Show 4 per line (as opposed to 3)
   ================================================== */

.taxonomy__section {
  .archive__item-title {
    margin-top: 0.5em;
  }
}

.taxonomy__index {
  @include breakpoint($large) {
    grid-template-columns: repeat(4, 1fr);
  }
}

pre {
  line-height: 1.1
}
